import { createI18n } from 'vue-i18n'
// 语言包
import zhCn from './zhCn'
import en from './en'
import useUserStore from '@/store/modules/user'
import { watch } from 'vue'

const userStore = useUserStore()

function formatLang(userLang: string) {
  switch (userLang) {
    case 'Simple Chinese':
      return 'zhCn'
    case 'English':
      return 'en'
    default:
      return 'en' // 提供一个默认值
  }
}

// 初始化i18n
const i18n = createI18n({
  legacy: false, // 设置为 false，启用 composition API 模式
  locale: formatLang(userStore.lang),
  fallbackLocale: 'en',
  messages: {
    zhCn,
    en,
  },
})

watch(
  () => userStore.lang,
  (newLang) => {
    i18n.global.locale.value = formatLang(newLang)
  },
)

export default i18n