//通过vue-router插件实现路由模版配置
import { createRouter, createWebHashHistory } from 'vue-router'
import { constantRoute, asyncRoute, anyRoute } from './routes'

let router = createRouter({
  //
  history: createWebHashHistory(),
  routes: constantRoute,
  //滚动行为
  scrollBehavior() {
    return {
      left: 0,
      top: 0,
    }
  },
})

export default router
