<template>
  <div class="myselect-body">
    <div class="title">
      {{ props.title }}
    </div>
    <el-select v-bind="$attrs">
      <template v-for="(_, name) in $slots" #[name]="slotData">
        <slot :name="name" v-bind="slotData || {}"></slot>
      </template>
    </el-select>
  </div>
</template>

<script setup lang="ts">
const props = defineProps(['title'])
</script>

<style scoped lang="scss">
.myselect-body {
  display: flex;
  justify-content: center;  
  align-items: center;
  gap: 10px; /* 添加间距，防止标题和输入框粘在一起 */
  width: 100%;

  .title {
    display: flex;
    width: 160px;
    color: $color;
    font-size: 16px;
    font-weight: bold;
  }

  .el-select {
    flex: 1;
  }
}
</style>
