//路由鉴权
import router from '@/router'
import setting from './setting'
import nprogress from 'nprogress'
import 'nprogress/nprogress.css'
//引入pinia
import pinia from './store'
//获取TOKEN
import useUserStore from './store/modules/user'

let userStore = useUserStore(pinia)
nprogress.configure({ showSpinner: false })

//前置守卫
router.beforeEach(async (to: any, from: any, next: any) => {
  //next:放行函数
  document.title = `${setting.title} - ${to.meta.title}`
  nprogress.start()
  let token = userStore.token
  let username = userStore.username
  if (token) {
    if (to.path == '/login') {
      next({ path: '/' }) 
    } else {
      if (username) {
        next()
      } else {
        try {
          await userStore.userInfo()
          next({...to})
        } catch (error) {
          // console.log(token)
          //token过期
          //or用户手动修改本地token
          await userStore.userLogout()
          next({ path: '/login', query: { redirect: to.path } })
        }
      }
    }
  } else {
    if (to.path == '/login') {
      next()
    } else {
      next({ path: '/login', query: { redirect: to.path } })
    }
  }
})
//后置守卫
router.afterEach((to: any, from: any) => {
  document.title = `${setting.title} - ${to.meta.title}`
  nprogress.done()
})
