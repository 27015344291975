//用户相关小仓库
import { defineStore } from 'pinia'
//引入接口
import { reqLogin, reqUserInfo, reqLogout } from '@/api/user'
//引入数据类型
import type {
  loginForm,
  loginResponseData,
  userInfoResponseData,
} from '@/types/user'
import type { Userstate } from '@/types/user'
//引入本地操作存储的工具方法
import { SET_TOKEN, GET_TOKEN, REMOVE_TOKEN } from '@/utils/token'
//引用路由（常量路由）
import { constantRoute, asyncRoute, anyRoute } from '@/router/routes'
import router from '@/router'

function filterAsyncRoute(asyncRoute: any, routes: string[]) {
  return asyncRoute.filter((item: any) => {
    if (routes.includes(item.name)) {
      if (item.children && item.children.length > 0) {
        item.children = filterAsyncRoute(item.children, routes)
      }
      return true
    }
  })
}

let useUserStore = defineStore('User', {
  //小仓库存储数据
  state: (): Userstate => {
    return {
      token: GET_TOKEN(), //用户唯一标识token
      username: '',
      avatar: '',
      theme: '',
      lang: '',
      font: '',
      menuRoutes: constantRoute, //仓库存储生成菜单需要的路由
    }
  },
  //异步、逻辑的地方
  actions: {
    //用户登录的方法
    async userLogin(data: loginForm) {
      let result: loginResponseData = await reqLogin(data)
      //成功200=》token
      if (result.code == 200) {
        this.token = result.data as string
        SET_TOKEN(result.data as string)
        //能保证async函数返回一个成功的promise
        return 'ok'
      }
      //失败201=》密码错误
      else {
        return Promise.reject(new Error(result.data as string))
      }
    },

    async userInfo() {
      let result: userInfoResponseData = await reqUserInfo()
      if (result.code == 200) {
        this.username = result.data.user_key
        this.avatar = result.data.avatar
        this.theme = result.data.theme
        this.lang = result.data.language
        this.font = result.data.font_size
        // let userAsyncRoute = filterAsyncRoute(asyncRoute, result.data.route)
        // this.menuRoutes = [...constantRoute, ...userAsyncRoute, anyRoute]
        // let newRoute = [...userAsyncRoute, anyRoute]
        // newRoute.forEach((route: any) => {
        //   router.addRoute(route)
        // })
        return 'ok'
      } else {
        return Promise.reject(new Error(result.message))
      }
    },

    async userLogout() {
      let result = await reqLogout()
      if (result.code == 200) {
        this.token = ''
        this.username = ''
        this.avatar = ''
        REMOVE_TOKEN()
        return 'ok'
      } else {
        return Promise.reject(new Error(result.message))
      }
    },
  },
  getters: {},
})

export default useUserStore
