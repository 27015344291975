<template>
  <el-popconfirm
    confirm-button-text="Yes"
    cancel-button-text="No"
    title="Are you sure to delete the selected item(s)?"
    width="200"
    @confirm="confirmEvent"
    @cancel="cancelEvent"
  >
    <template #reference>
      <el-button class="button" :size="size" :disabled="disabled">
        <template #icon>
          <SvgIcon :name="name" width="24px" height="24px" :color="computedColor" />
        </template>
      </el-button>
    </template>
  </el-popconfirm>
</template>

<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps({
  name: { type: String, default: 'delete' },
  size: { type: String, default: 'default' },
  color: { type: String, default: 'var(--theme-color)' },
  disabled: { type: Boolean, default: false }
})

const computedColor = computed(() => {
  return props.disabled ? '#C0C4CC' : props.color
})

const confirmEvent = () => {
  console.log('confirm!')
}
const cancelEvent = () => {
  console.log('cancel!')
}
</script>

<style scoped lang="scss">
.button :deep(.el-icon) {
  width: 24px;
  height: 24px;
}

.radio-group :deep(.el-radio-button__inner) {
  padding: 8px;
}
</style>
