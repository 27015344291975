<template>
  <el-button class="button" :size="size" :disabled="disabled">
    <template #icon>
      <SvgIcon :name="name" :color="computedColor" width="24px" height="24px"/>
    </template>
  </el-button>
</template>

<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps({
  name: { type: String, default: 'delete' },
  size: { type: String, default: 'default' },
  color: { type: String, default: 'var(--theme-color)'},
  disabled: { type: Boolean, default: false }
})

const computedColor = computed(() => {
  return props.disabled ? '#C0C4CC' : props.color
})
</script>

<style scoped lang="scss">
.button :deep(.el-icon) {
  width: 24px;
  height: 24px;
}

.radio-group :deep(.el-radio-button__inner) {
  padding: 8px;
}
</style>
