<template>
    <div>
        <el-card v-bind="$attrs">
            <template v-for="(value, name) in $slots" #[name]="slotData">
                <slot :name="name" v-bind="slotData || {}"></slot>
            </template>
        </el-card>
    </div>
</template>

<script setup lang="ts">
</script>

<style lang="scss" scoped></style>