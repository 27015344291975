//统一管理用户相关接口
import http from '@/utils/http'
import type {
  loginForm,
  loginResponseData,
  userInfoResponseData,
} from '@/types/user'
import { updateSftwSettingReq } from '@/types/system'

// 获取基础URL
const prefix = '/user'

// 使用常量而不是枚举来管理 URL
const API = {
  LOGIN_URL: `${prefix}/login`,
  LOGOUT_URL: `${prefix}/logout`,
  USER_URL: `${prefix}/0100-dim-user`,
}

//暴露请求函数
//LOGIN
export const reqLogin = (data: loginForm) =>
  http.post<any, loginResponseData>(API.LOGIN_URL, data)

//LOGOUT
export const reqLogout = () => http.post<any, any>(API.LOGOUT_URL)

//USER
export const reqUserInfo = () =>
  http.get<any, userInfoResponseData>(API.USER_URL)