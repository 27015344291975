import { createApp } from 'vue'
import App from '@/App.vue'
//引入element-plus的插件和样式
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
//配置element中文
// import zhCn from 'element-plus/es/locale/lang/zh-cn'（使用的时候需要在下方配置）
//svg配置
import 'virtual:svg-icons-register'
//引入自定义插件对象：注册整个项目的全局组件
import globalComponent from '@/components'
//全局样式
import '@/styles/index.scss'
//引入路由
import router from './router'
//引入仓库pinia
import pinia from './store'
//引入路由鉴权
import './permission'
//引入i18n
import i18n from './i18n'
//引入暗黑模式
import 'element-plus/theme-chalk/dark/css-vars.css'
//获取应用实例对象
const app = createApp(App)
//安装Element_plus插件
app.use(ElementPlus)
//安装自定义插件
app.use(globalComponent)
//安装路由
app.use(router)
//安装仓库pinia
app.use(pinia)
//安装i18n插件
app.use(i18n)
//应用挂载
app.mount('#app')
