import axios from 'axios';
import useUserStore from '@/store/modules/user';

// 定义 API_KEY
const API_KEY = "kjlahfohaioerhllknlkhiu989";

// 创建 axios 实例
let http = axios.create({
  baseURL: import.meta.env.VITE_SERVE,
  timeout: 5000,
});

// 请求拦截器
http.interceptors.request.use((config) => {
  // 获取 token
  let userStore = useUserStore();
  if (userStore.token) {
    // 如果存在 token，则添加到请求头中
    config.headers['token'] = userStore.token;
  }

  // 添加 API_KEY 到请求头
  config.headers['Authorization'] = `Bearer ${API_KEY}`;

  return config;
}, (error) => {
  return Promise.reject(error);
});

// 响应拦截器
http.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    console.error('There was an error fetching the data:', error);
    return Promise.reject(error);
  },
);

export default http;
